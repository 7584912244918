import { Grid } from "@mui/material";
import lineImage from "../../../images/Line 3.png";
import { ourmissiontext } from "../../Constants";
import styles from "./AboutUs.module.css";
import Mainstyle from "../../../style.module.css";
import Ourmissionimg from "../../../images/ourmission-img1.png";

function OurMission() {
  
  return (
    <div>
      
      <Grid container alignItems="center">
        <Grid item xs={12} md={6} className={Mainstyle.containerwrap}>
          <img src={lineImage} className={styles.lineImage} alt="Desi Hisab" />
          <span className={Mainstyle.incomeTitleexpenses}>Our Mission</span>
          <div>
          <span className={Mainstyle.title}>
            Effortless Expense Management
            <br /> for a Better Future
          </span></div>
          {ourmissiontext.map((data) => (
            <div key={data.id} className={`${Mainstyle.flexwrapper} ${Mainstyle['mt-51']}`}>
              <div className={Mainstyle.circlewithlogo}>
                <div className={Mainstyle.circlewithtext}>
                  <img src={data.icon} alt="Desi Hisab" />
                </div>
              </div>
              <div className={Mainstyle.icontextwrap}>
                <span className={Mainstyle.textheading}>{data.heading}</span>
                <span className={Mainstyle.textdescription}>
                  {data.description}
                </span>
              </div>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} md={6} className={styles.imgright}>
          <img src={Ourmissionimg} alt="Desi Hisab Mission" />
        </Grid>
      </Grid>
    </div>
  );
}

export default OurMission;
