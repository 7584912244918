import React, { useEffect, useState } from "react";
import "./index.css";
import "./style.module.css";
import { Route, Routes } from "react-router-dom";
import Index from "./components/Index";
import PrivacyPage from "./components/PrivacyPolicy/PrivacyPage";
import FooterSecurity from "./components/FooterSecurity/FooterSecurity";
import AboutUs from "./components/Pages/About/AboutUs";
import Features from "./components/Pages/Features/Features";
import FooterFAQs from "./components/FooterFAQs/FooterFAQs";
import Error404Page from "./components/404/Error404Page";

function App() {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://desihisab.s3.ap-south-1.amazonaws.com/faqs.json");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setJsonData(data);
        localStorage.setItem("jsonData", JSON.stringify(data));
      } catch (error) {
        let storedData = localStorage.getItem("jsonData");
        if (storedData) {
          setJsonData(JSON.parse(storedData));
        }
        console.error("Error fetching JSON data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/legal" element={<PrivacyPage />} />
      <Route path="/security" element={<FooterSecurity />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/features" element={<Features />} />
      <Route path="/faqs" element={<FooterFAQs jsonData={jsonData} />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}
export default App;

