import React, { useEffect } from "react";
import FooterSection from "../Footer/FooterSection";
import Navbar from "./Navbar";
import { Box } from "@mui/material";
import PrivacyHeader from "./PrivacyHeader";
import backgroundImage from "../../images/backgroundimg.png";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <Box>
      <Navbar
        pageName={{
          navbarName: "PRIVACY POLICY",
          backimg: backgroundImage,
        }}
      />
      <PrivacyHeader />
      <FooterSection />
    </Box>
  );
};

export default PrivacyPage;
