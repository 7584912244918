import React from "react";
import styles from "./Features.module.css";
import Mainstyle from "../../../style.module.css";
import { featurestext } from "../../Constants";
import { Grid, Typography } from "@mui/material";

const Featuresofdesihisab = () => {
  return (
    <>
    
      <Grid container className={Mainstyle.mainWrap}>
        {featurestext.map((data) => (
          <Grid item xs={12} md={4} className={styles.featurecolwrap} >
            <div className={styles.featurecol} >
            <img src={data.icon} alt="Desi Hisab" />
            <div className={`${styles.textheading} ${styles.featureboxtitle}`}>
              {data.heading}
            </div>
            <div className={`${styles.textdescription} ${styles.featureboxdesc}`}>
              {data.description}
            </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Featuresofdesihisab;
