import React, { useEffect } from "react";
import shareableExp from "../../images/shareExp.webp";
import styles from "../shareableExpenses/SharableExpense.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function ShareableExpensesLogo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={styles.ShareableExpLogo}>
      <img src={shareableExp} alt="Desi Hisab"/>
    </div>
  );
}

export default ShareableExpensesLogo;

