import React from "react";
import invitefriend_res from "../../images/Inviteyourfriends.webp";
import styles from "../inviteFriends/InviteFriends.module.css";

function InviteFriendLogo() {
  return (
    <>
      <div className={styles.invitefriendlogo}>
        <img className={styles.invitefriend_res} src={invitefriend_res} alt="Desi Hisab" />
      </div>
    </>
  );
}

export default InviteFriendLogo;
