import LoginForm from "./LoginForm";
import { Grid } from "@mui/material";
import DesiHisabLogo from "./DesiHisabLogo";
import styles from "./HeaderSection.module.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Container from "@mui/material/Container";
import { useEffect } from "react";
function HeaderSection() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className={styles.headersection_mainsection}>
      <Container maxWidth="xl">
        <Grid container className={styles.headersection_mainwrap}>
          <Grid item xs={12} md={6} sm={12} className={styles.logosection}>
            <DesiHisabLogo className={styles.desi} />
          </Grid>

          <Grid item xs={12} md={6} sm={12} className={styles.loginformsection}>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HeaderSection;
