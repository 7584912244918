import React, { useEffect } from "react";
import Navbar from "../../PrivacyPolicy/Navbar";
import { Box, Container, Grid } from "@mui/material";
import Getstarted from "../About/Getstarted";
import Featuresofdesihisab from "./Featuresofdesihisab";
import FooterSection from "../../Footer/FooterSection";
import backgroundImage from "../../../images/backgroundimg.png";
import Mainstyle from "../../../style.module.css";
import styles from "./Features.module.css";
import { featuresTitle } from "../../Constants";

const Features = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <>
      <Box>
        <Navbar
          pageName={{
            navbarName: "FEATURES",
            backimg: backgroundImage,
          }}
        />
      </Box>
      <Grid container className={styles.featuresmainwrap}>
        <Grid item xs={12}>
          <div className={styles.title}>{featuresTitle.title}</div>
          <div className={styles.subtitle}>{featuresTitle.subTitle}</div>
        </Grid>
      </Grid>
      <Container Container maxWidth="xl" className={styles.Featuresofdesihisabwrap}>
        <Grid item xs={12} >
          <Featuresofdesihisab/>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Grid item xs={12} className={Mainstyle.containermainwrap}>
          
          <Getstarted />
        </Grid>
      </Container>
      <FooterSection />
    </>
  );
};
export default Features;
