import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import {
  headerTitle,
  buttonDataPlayStore,
  buttonDataAppleStore,
  headerHeading,
  handalClickgoogleplay,
  handalClickAppleStore,
} from "../Constants.js";
import styles from "./LoginForm.module.css";
import Avatar from "@mui/material/Avatar";
import { loginApi } from "../api.js";

import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import ReplayIcon from "@mui/icons-material/Replay";

function LoginForm() {
  const { button } = buttonDataPlayStore;
  const { buttons } = buttonDataAppleStore;
  const { mainText } = headerHeading;
  const { className, title } = headerTitle.appTitle;

  const [phone, setPhone] = useState("");
  const [errorColor, setErrorColor] = useState("");
  const [loginSuccess, setLoginSuccess] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [numberArray, setNumberArray] = useState([]);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [loader, setLoader] = useState(false);

  const loginFun = async () => {
    if (!navigator.onLine) {
      if (phone.length !== 10 || !/^[6-9]\d{9}$/.test(phone)) {
        setPhoneError(true);
        setNetworkError("");
      } else {
        setPhoneError(false);
        setLoader(false);
        setNetworkError(
          "You are currently offline. Please check your internet connection."
        );
        // setLoginSuccess(
        //   "You are currently offline. Please check your internet connection."
        // );
      }
      return;
    }
    setLoader(true);

    const phoneNumberPattern = /^[6-9]\d{9}$/;
    if (phone.length === 10 && phoneNumberPattern.test(phone)) {
      const formdata = new FormData();
      formdata.append("mobile_number", phone);

      try {
        if (loginAttempts < 2) {
          const result = await loginApi(formdata);
          if (result.data.status === "200") {
            setNetworkError("");
            setPhone("");
            setLoginSuccess(result.data.message);
            setPhoneError(false);
            setNumberArray((prevArray) => {
              setLoader(false);
              if (
                prevArray.length > 0 &&
                prevArray[prevArray.length - 1] === phone
              ) {
                setLoginAttempts(loginAttempts + 1);
                return [...prevArray, phone];
              } else {
                setLoginAttempts(1);
                return [phone];
              }
            });
            setErrorColor(result.data.status);
          } else {
            setErrorColor(result.data.status);
            setLoginSuccess(result.data.message);
            setPhone("");
            setLoader(false);
          }
        } else {
          if (numberArray[numberArray.length - 1] === phone) {
            setOpenPopup(true);
            setLoader(false);
            setLoginAttempts(0);
          } else {
            setLoginAttempts(loginAttempts + 1);
            setNumberArray((prevArray) => [...prevArray, phone]);
            const result = await loginApi(formdata);
            if (result.data.status === "200") {
              setPhone("");
              setLoginSuccess(result.data.message);
              setPhoneError(false);
              setLoginAttempts(1);
              setErrorColor(result.data.status);
            } else {
              setErrorColor(result.data.status);
              setLoginSuccess(result.data.message);
              setPhone("");
            }
          }
        }
      } catch (error) {
        setLoginSuccess("");
      }
      setPhoneError(false);
    } else {
      setPhoneError(true);
      setLoader(false);
      setLoginSuccess("");
    }
  };

  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setPhone(numericValue);
    setLoginSuccess("");
  };

  const handlePhoneBlur = () => {
    setTimeout(() => {
      if (phone.length !== 10 || !/^[6-9]\d{9}$/.test(phone)) {
        setPhoneError(true);
        setLoginSuccess("");
        setNetworkError("");
      }
    }, 100);
  };

  useEffect(() => {
    if (openPopup) {
      const initializeCaptcha = async () => {
        await new Promise((resolve) => {
          loadCaptchaEnginge(6);
          document.addEventListener("DOMContentLoaded", resolve);
        });
      };
      setTimeout(() => {
        initializeCaptcha();
      }, 0);
    }
  }, [openPopup]);

  const captchaSubmit = () => {
    let userCaptcha = document.getElementById("user_captcha_input").value;

    if (validateCaptcha(userCaptcha) === true) {
      loadCaptchaEnginge(6);
      document.getElementById("user_captcha_input").value = "";
      loginFun();
      setOpenPopup(false);
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
      document.getElementById("user_captcha_input").value = "";
    }
  };

  const handleReloadCaptcha = () => {
    loadCaptchaEnginge(6);
  };

  const handleDrop = (event) => {
    event.preventDefault();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginFun();
    }
  };

  return (
    <>
      <Box className={styles.loginformmainwrap}>
        <div className={styles[className]}>
          <h2 className={styles.title}>{title}</h2>
        </div>

        <div className={styles[mainText.className]}>
          <h1 className={styles[mainText.text.className]}>
            {mainText.text.content}
          </h1>
        </div>

        <div className={styles.textfieldmainwrap}>
          <TextField
            className={`${styles.textfieldwrap} ${
              isFocused ? styles.focused : ""
            }`}
            size="medium"
            placeholder="Enter your phone number"
            value={phone}
            onChange={handlePhoneChange}
            onBlur={handlePhoneBlur}
            onDrop={handleDrop}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            InputProps={{
              style: {
                fontSize: 16,
                width: "100%",
              },
              inputProps: { maxLength: 10 },
              pattern: "[6-9][0-9]{9}",
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={styles.InputAdornment}
                >
                  <span>+91</span>
                </InputAdornment>
              ),
            }}
          />
          {phoneError && (
            <Box className={styles.invalidPhoneNumberMessagemobile}>
              Please enter a valid phone number
            </Box>
          )}
          <Box
            style={{ color: errorColor === "200" ? "green" : "red" }}
            className={styles.loginSuccessMessagemobile}
          >
            {loginSuccess}
          </Box>
          <Box className={styles.networkErrorMessagemobile}>{networkError}</Box>

          <Button onClick={loginFun} className={styles.startedbutton}>
            {loader === false ? (
              "Get Started"
            ) : (
              <Box className={styles.loaderLogin}>
                <Stack sx={{ color: "grey" }} spacing={2} direction="row">
                  <CircularProgress color="inherit" size={22} />
                </Stack>
              </Box>
            )}
          </Button>
        </div>

        {phoneError && (
          <Box className={styles.invalidPhoneNumberMessage}>
            Please enter a valid phone number
          </Box>
        )}
        <Box
          style={{ color: errorColor === "200" ? "green" : "red" }}
          className={styles.loginSuccessMessage}
        >
          {loginSuccess}
        </Box>
        <Box className={styles.networkErrorMessage}>{networkError}</Box>

        <Grid container className={styles.gridwrapbuttoncontainer}>
          <Grid className={styles.btngrid}>
            <Button
              sx={button.styles}
              startIcon={
                <Avatar
                  className={styles[button.startIcon.className]}
                  src={button.startIcon.src}
                />
              }
              onClick={handalClickgoogleplay}
              className={styles[button.className]}
            >
              <div className={styles[button.content.wrapClassName]}>
                {button.content.text.map((textItem, index) => (
                  <span key={index} style={textItem.style}>
                    <span className={styles.getitgooleplay}>
                      {textItem.content}
                    </span>
                  </span>
                ))}
              </div>
            </Button>
            <Button
              sx={buttons.styles}
              startIcon={
                <Avatar
                  className={styles[buttons.startIcon.className]}
                  src={buttons.startIcon.src}
                />
              }
              onClick={handalClickAppleStore}
              className={styles[buttons.className]}
            >
              <div className={styles[buttons.content.wrapClassName]}>
                {buttons.content.text.map((textItem, index) => (
                  <span key={index} style={textItem.style}>
                    <span className={styles.getitgooleplay}>
                      {textItem.content}
                    </span>
                  </span>
                ))}
              </div>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={openPopup}
        closeAfterTransition
        className={styles.loginCaptchaModal}
      >
        <Box className={styles.captchaBox}>
          <Box className={styles.loadCanvas}>
            <LoadCanvasTemplateNoReload />
            <ReplayIcon
              className={styles.reloadCaptcha}
              onClick={handleReloadCaptcha}
            />
          </Box>

          {captchaError && (
            <span className={styles.captchaErrorMessage}>
              Captcha Does Not Match
            </span>
          )}

          <Box className={styles.captchaTextfieldmain}>
            <TextField
              className={styles.captchaTchatextfiel}
              size="medium"
              placeholder="Enter Captcha"
              id="user_captcha_input"
              name="user_captcha_input"
              InputProps={{
                style: {
                  fontSize: 16,
                  // height: 44,
                  width: "100%",
                  // padding: "0",
                },
              }}
            />
            <Button className={styles.startedbutton} onClick={captchaSubmit}>
              Get Started
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default LoginForm;
