import { Grid } from "@mui/material";
import InsightsReports from "./InsightsReports";
import styles from './InsightReportSection.module.css';
import InsightReportsLogo from "./InsightReportsLogo";
import Container from '@mui/material/Container';
function ReportsSection() {
  return (
    <div>
      <Container maxWidth="xl">
      <Grid container className={styles.ReportsSection_mainwrap}>
        <Grid item xs={12} md={6} className={styles.logosection}>
          <InsightReportsLogo />
        </Grid>

        <Grid item xs={12} md={6} className={styles.ReportsSection}>
          <InsightsReports />
        </Grid>

      </Grid>
      </Container>
    </div>
  )
}

export default ReportsSection;
