import { Grid } from "@mui/material";
import lineImage from "../../images/Line 3.png";
import styles from "./SharableExpense.module.css";
import { shareAbleExpense } from "../Constants.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function SharableExpense() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <img src={lineImage} className={styles.lineImage} alt="Desi Hisab" />
      <span className={styles.incomeTitleexpenses}>share</span>
      <div className={styles.expensesmaintitlewrap}>
        <span className={styles.title}>Sharable Expense</span>
      </div>
      <>
        {shareAbleExpense.map((data) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={data.id}
            className={styles.containerwrap}
          >
            <Grid item className={styles.circlewithlogo}>
              <div className={styles.circlewithtext}>
                <img src={data.icon} alt="Desi Hisab" />
              </div>
            </Grid>
            <Grid item xs={12} md={10}>
              <span className={styles.textheading}>{data.heading}</span>
              <span className={styles.textdescription}>{data.description}</span>
            </Grid>
          </Grid>
        ))}
      </>
    </div>
  );
}

export default SharableExpense;
