import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./errorpage.module.css";
import errorimage from "../../images/404error.webp";

const Errorsection = () => {
  return (
    <Grid container>
      <Grid item xs={12} className={styles.errorheader} >
        <Box>
          <img className={styles.errorImg} src={errorimage} alt="Desi Hisab" />
          <Typography className={styles.errortext}>PAGE NOT FOUND</Typography>
        </Box>
        <NavLink to="/">
          <button className={styles.gobackbtn}>Go Back Home Page</button>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default Errorsection;
