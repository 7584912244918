import React, { useEffect } from "react";
import fastsecure from "../../images/fastSecure.webp";
import styles from "../FreeFastSecure/FreeFastSecure.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function FastSecureSectionLogo() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={styles.fastimg}>
      <img src={fastsecure} alt="Desi Hisab"/>
    </div>
  );
}

export default FastSecureSectionLogo;
