import { Box, Grid } from "@mui/material";
import FooterLogo from "./FooterLogo";
import FooterLink from "./FooterLink";
import FooterLogin from "./FooterLogin";
import styles from "./FooterSection.module.css";

function FooterSection({ handleClick }) {
  return (
    <Grid container className={styles.footercontainer}>
      <Box className={styles.footer_mainwrap}>
        <Grid item xs={12} md={6} className={styles.footerloginwrap}>
          <FooterLogin />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={styles.footerlinkwrap}>
          <FooterLink handleClick={handleClick} />
        </Grid>
        <Grid item xs={12} md={3} className={styles.footerlogowrap}>
          <FooterLogo />
        </Grid>
      </Box>
    </Grid>
  );
}

export default FooterSection;
