import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Navbar from "../../PrivacyPolicy/Navbar";
import FooterSection from "../../Footer/FooterSection";
import backgroundImage from "../../../images/backgroundimg.png";
import WelcomeSection from "./Welcome";
import OurMission from "./Ourmission";
import Getstarted from "./Getstarted";
import { Whychooseus } from "./Whychooseus";
import { Grid, Container } from "@material-ui/core";
import styles from "./AboutUs.module.css";
import Mainstyle from "../../../style.module.css";
import Howtouseapp from "./Howtouseapp";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <>
      <Box>
        <Navbar
          pageName={{
            navbarName: "ABOUT US",
            backimg: backgroundImage,
          }}
        />
      </Box>

      <Container maxWidth="xl">
        <Grid item xs={12} className={Mainstyle.containermainwrap}>
          <WelcomeSection />
          <OurMission />
        </Grid>
      </Container>
      <Whychooseus />
      <Container maxWidth="xl">
        <Grid item xs={12} className={Mainstyle.containermainwrap}>
          <Getstarted />
        </Grid>
      </Container>
      <Container className={styles.howtouseappbgcolor} maxWidth="xl">
        <Grid item xs={12} className={styles.howtouseappmainwrap}>
        <Howtouseapp />   
        </Grid>
        </Container>
      
      <FooterSection />
    </>
  );
};
export default AboutUs;
