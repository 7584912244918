import React from "react";
import styles from "../About/AboutUs.module.css";
import Mainstyle from "../../../style.module.css";
import { Grid, Typography } from "@mui/material";
import { whychooseustext } from "../../Constants";

export const Whychooseus = () => {
  return (
    <section className={styles.whyusmainwrap}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Why Choose Desi Hisab?</Typography>
        </Grid>
      </Grid>
      <Grid container className={Mainstyle.mainWrap}>
        {whychooseustext.map((data) => (
          <Grid item xs={12} md={3} className={styles.whyuscol}>
            <img src={data.icon} alt="Desi Hisab" />
            <div className={`${styles.textheading} ${Mainstyle.textheading}`}>{data.heading}</div>
            <div className={styles.divider}></div>
            <div className={`${styles.textdescription} ${Mainstyle.textdescription}`}>{data.description}</div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
