import React, { useEffect } from "react";
import HeaderSection from "./Header/HeaderSection";
import ExpenseSection from "./Expenses/ExpenseSection";
import ShareableExpensSection from "./shareableExpenses/ShareableExpensSection";
import FastSecureSection from "./FreeFastSecure/FastSecureSection";
import TestimonialSection from "./Testimonials/TestimonialSection";
import ReportsSection from "./InsightsReports/ReportsSection";
import InviteFriendsSection from "./inviteFriends/InviteFriendsSection";
import FooterSection from "./Footer/FooterSection";

// const HeaderSection = lazy(() => import("../components/Header/HeaderSection"));
// const FooterSection = lazy(() => import("../components/Footer/FooterSection"));
// const ExpenseSection = lazy(() =>
//   import("../components/Expenses/ExpenseSection")
// );
// const ReportsSection = lazy(() =>
//   import("../components/InsightsReports/ReportsSection")
// );
// const FastSecureSection = lazy(() =>
//   import("../components/FreeFastSecure/FastSecureSection")
// );
// const TestimonialSection = lazy(() =>
//   import("../components/Testimonials/TestimonialSection")
// );
// const InviteFriendsSection = lazy(() =>
//   import("../components/inviteFriends/InviteFriendsSection")
// );
// const ShareableExpensSection = lazy(() =>
//   import("../components/shareableExpenses/ShareableExpensSection")
// );

const Index = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="">
      {/* <Suspense fallback={<div></div>}> */}
        <HeaderSection />
        <ExpenseSection />
        <ShareableExpensSection />
        <FastSecureSection />
        <TestimonialSection />
        <ReportsSection />
        <InviteFriendsSection />
        <FooterSection />
      {/* </Suspense> */}
    </div>
  );
};

export default Index;
