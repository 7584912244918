import React from "react";
import {
  getstartedtodaytetxt,
  buttonDataAppleStore,
  buttonDataPlayStore,
  socialMediaLinks,
  handalClickgoogleplay,
  handalClickAppleStore,
} from "../../Constants";
import Avatar from "@mui/material/Avatar";
import { Button, Grid } from "@mui/material";
import manimage from "../../../images/Layer07651.webp";
import lineImage from "../../../images/Line 3.png";
import styles from "./AboutUs.module.css";
import Mainstyle from "../../../style.module.css";

const Getstarted = () => {
  const { button } = buttonDataPlayStore;
  const { buttons } = buttonDataAppleStore;
  return (
    <section className={styles.getstartedwrap}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={12}  lg={6} className={styles.getstartedimgwrap}>
          <img
            src={manimage}
            alt="Get Started"
            className={styles.getstartedimg}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <img src={lineImage} className={styles.lineImage} alt="Desi Hisab" />
          <span className={styles.incomeTitleexpenses}>
            Begin Right Away
          </span>
          {getstartedtodaytetxt.map((data) => (
            <div key={data.id} className={styles.Getstartedrightcol}>
              <div className={Mainstyle.icontextwrap}>
                <span className={styles.title}>{data.heading}</span>
                <span className={styles.getstartedtext}>
                  {data.description}
                </span>
              </div>
              <Grid container className={styles.gridwrapbuttoncontainer}>
              <Grid
          item
          // xs={12}
          sm={5}
          md={6}
          lg={6}
          xl={5}
          className={styles.btngrid}
        >
                  <Button
                    sx={button.styles}
                    startIcon={
                      <Avatar
                        className={styles[button.startIcon.className]}
                        src={button.startIcon.src}
                      />
                    }
                    onClick={handalClickgoogleplay}
                    className={styles[button.className]}
                  >
                    <div className={styles[button.content.wrapClassName]}>
                      {button.content.text.map((textItem, index) => (
                        <span
                          key={index}
                          className={styles.googleplay}
                          style={textItem.style}
                        >
                          <span>{textItem.content}</span>
                        </span>
                      ))}
                    </div>
                  </Button>
                </Grid>
                {/* <Grid item className={styles.space} md={0}  lg={1}/> */}
                <Grid
                  item
                  // xs={12}
                  sm={5}
                  md={6}
                  lg={6}
                  xl={5}
                  className={styles.gridwrapbutton}
                >
                  <Button
                    sx={buttons.styles}
                    startIcon={
                      <Avatar
                        className={styles[buttons.startIcon.className]}
                        src={buttons.startIcon.src}
                      />
                    }
                    onClick={handalClickAppleStore}
                    className={styles[buttons.className]}
                  >
                    <div className={styles[buttons.content.wrapClassName]}>
                      {buttons.content.text.map((textItem, index) => (
                        <span
                          key={index}
                          className={styles.googleplay}
                          style={textItem.style}
                        >
                          <span>{textItem.content}</span>
                        </span>
                      ))}
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </div>
          ))}
        </Grid>
      </Grid>
    </section>
  );
};

export default Getstarted;
