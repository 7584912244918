import React from "react";
import styles from "../PrivacyPolicy/Privacypage.module.css";
import { Box, Grid, Typography } from "@mui/material";

const PrivacyHeader = () => {
  return (
    <Grid container className={styles.mainPrivacyHeader}>
      <Grid item xs={12}>
        <Box className={styles.pivacyMainText}>
          <Box>
			<Typography className={styles.privacyPolicyInformation}>
				Effective Date: March 21, 2024<br/>
			</Typography>
            <Typography className={styles.privacyPolicyHead}>
              Desi Hisab Privacy Policy: Your Privacy Matters
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Desi Hisab mission is to enable real time instant transaction managing and sharing to connect various hisab. Central to this mission is our commitment to be
              transparent about the data we collect about you, how it is used
              and with whom it is shared. This Privacy Policy applies when you
              use our Services (described below). We offer our users choices
              about the data we collect and use, can that be shared as described
              in this Privacy Policy, Cookie Policy. Settings are available to
              Members of Desi Hisab, and Visitors are provided controls.
            </Typography>
          </Box>
          <Box>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Introduction
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We are a community enabling solution. People use our Services to
              create communities for various needs. This includes business,
              opportunities, learning, teams among others. Our Privacy Policy
              applies to any Member or Visitor to our Services.
            </Typography>
            <br />
            <Typography>
              Our registered users ("Members") share their Hisab, Transaction with their friends, family and
              other knon person. .
            </Typography>
          </Box>
          <Box>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              This Privacy Policy, including our Cookie Policy, applies to your
              use of our Services. This Privacy Policy applies to Desi
              Hisab., Desi Hisab-branded apps, Desi Hisab and other Desi
              Hisab-related sites, apps, communications and services
              ("Services"), including off-site Services, such as our ad services
              and the "Apply with Desi Hisab" and "Share with Desi Hisab"
              plugins, but excluding services that state that they are offered
              under a different privacy policy.
            </Typography>
          </Box>
          <Box>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Data Controllers and Contracting Parties
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              If you reside in the "Designated Countries": SecureMetaSys
              Infotech Pvt. Ltd. Company ("Desi Hisab") will be the controller
              of your personal data provided to, or collected by or for, or
              processed in connection with our Services; you are entering into
              the User Agreement with Desi Hisab.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              If you reside outside of the Designated Countries: SecureMetaSys
              Infotech Pvt. Ltd. will be the controller of your personal data
              provided to, or collected by or for, or processed in connection
              with our Services; you are entering into the User Agreement with
              SecureMetaSys Infotech Pvt. Ltd. As a Visitor or Member of our
              Services, the collection, use and sharing of your personal data is
              subject to this Privacy Policy (which includes our Cookie Policy
              and other documents referenced in this Privacy Policy) and
              updates.
            </Typography>
          </Box>
          <Box>
            <br />
            <br />
            <Typography className={styles.privacyPolicyHead}>Change</Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Changes to the Privacy Policy apply to your use of our Services
              after the "effective date." Desi Hisab ("we" or "us") can modify
              this Privacy Policy, and if we make material changes to it, we
              will provide notice through our Services, or by other means, to
              provide you the opportunity to review the changes before they
              become effective. If you object to any changes, you may close your
              account.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              You acknowledge that your continued use of our Services after we
              publish or send a notice about our changes to this Privacy Policy
              means that the collection, use and sharing of your personal data
              is subject to the updated Privacy Policy.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1. Data We Collect			  
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
			Desi Hisab must receive or collect some information to operate, provide, improve, understand, customize, support, and market our Services, including when you install, access, or use our Services.
			</Typography>
			<br />
			<Typography className={styles.privacyPolicyInformation}>
			The types of information we receive and collect depend on how you use our Services. We require certain information to deliver our Services and without this we will not be able to provide our Services to you. For example, you must provide your mobile phone number to create an account to use our Services.
			</Typography>
			<br />
			<Typography className={styles.privacyPolicyInformation}>
			Our Services have optional features which, if used by you, require us to collect additional information to provide such features. You will be notified of such collection, as appropriate. If you choose not to provide the information needed to use a feature, you will be unable to use the feature. For example, you cannot share your hisab with your contacts if you do not permit us to collect your phonebook data from your device. Permissions can be managed through your Settings menu on both Android and iOS devices.
			</Typography>
			<br />
			<Typography className={styles.privacyPolicyInformation}>
			Information You Provide
			</Typography>
			<br />
            <Typography className={styles.privacyPolicyInformation}>
              1.1 Data You Provide To Us
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              You provide data to create an account with us.
            </Typography>
            <br />
			<Typography className={styles.privacyPolicyHead}>
              Your Account Information
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              You must provide your mobile phone number and basic information (including a name of your choice) to create a DesiHisab account. If you don’t provide us with this information, you will not be able to create an account to use our Services. You can add other information to your account, such as a profile picture.
            </Typography>
			<br />
			<Typography className={styles.privacyPolicyHead}>
              Your Connections
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              You can use the contact upload feature and provide us, if permitted by applicable laws, with the phone numbers in your address book on a regular basis, including those of users of our Services and your other contacts. If any of your contacts aren’t yet using our Services, we’ll manage this information for you in a way that ensures those contacts cannot be identified by us.
				Desi Hisab collects contact details to allow user to invite their friends on this application to contribute into the Hisab on Invite Participant module. We use end to end encryption to transmit data and store information in encrypted format. We use Image in profile to Identify user within Hisab shared with friends.
            </Typography>
			<br />
          </Box>          
          <br />
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              1.2 Data From Others
            </Typography>           
            <Typography className={styles.privacyPolicyHead}>
              Customers and partners may provide data to us.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Partners
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We receive personal data about you when you use the services of
              our customers and partners, such as employers, prospective
              employers and applicant tracking systems providing us job
              application data.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Related Companies and Other Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We receive data about you when you use some of the other services
              provided by us or our affiliates.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Affiliates are companies controlling, controlled by or under
              common control with us, including, for example, Desi Hisab.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              1.3 Service Use
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We log your visits and use of our Services, including mobile apps.
              We log usage data when you visit or otherwise use our Services,
              including our sites, app and platform technology (e.g., our
              off-site plugins), such as when you view or click on content
              (e.g., learning video) or ads (on or off our sites and apps),
              perform a search, install or update one of our mobile apps, share
              articles or apply for jobs. We use log-ins, cookies, device
              information and internet protocol ("IP") addresses to identify you
              and log your use.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.4 Cookies, Web Beacons and Other Similar Technologies
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We collect data through cookies and similar technologies.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              similar technologies (e.g., web beacons, pixels, ad tags and
              device identifiers) to recognize you and/or your device(s) on, off
              and across different Services and devices. We also allow some
              others to use cookies as described in our Cookie Policy. You can
              control cookies through your browser settings and other tools. You
              can also opt-out from our use of cookies and similar technologies
              that track your behavior on the sites of others for third party
              advertising. For Visitors, the opt-out is here.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.5 Your Device and Location
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We receive data from your devices and networks, including location
              data.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              When you visit or leave our Services (including our plugins or
              cookies or similar technology on the sites of others), we receive
              the URL of both the site you came from and the one you go to next.
              We also get information about your IP address, proxy server,
              operating system, web browser and add-ons, device identifier and
              features, and/or ISP or your mobile carrier. If you use our
              Services from a mobile device, that device will send us data about
              your location based on your phone settings. We will ask you to
              opt-in before we use GPS or other tools
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              e.g. Bluetooth to identify your precise location.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.6 Messages
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              If you communicate through our Services, we learn about that.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We collect information about you when you send, receive, or engage
              with messages in connection with our Services. For example, if you
              get a Desi Hisab connection request, we track whether you have
              acted on it and will send you reminders.
            </Typography>
          </Box>
          <Box>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.7 Workplace and School Provided Information
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              When your employer or school buys a premium Service for you to
              use, they give us data about you. Others buying our Services for
              your use, such as your employer or your school, provide us with
              personal data about you and your eligibility to use the Services
              that they purchase for use by their workers, students or alumni.
              For example, we will get contact information for "Company Page"
              administrators and for authorizing users of our premium Services,
              such as our recruiting, sales or learning products.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.8 Sites and Services of Others
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We get data when you visit sites that include our plugins, ads or
              cookies or log-in to others’ services with your Desi Hisab
              account.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We receive information about your visits and interaction with
              services provided by others when you log-in with Desi Hisab or
              visit others’ services that include our plugins (such as "Share on
              Desi Hisab" or "Apply with Desi Hisab"), ads, cookies or similar
              technologies.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              1.9 Other
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We are improving our Services, which means we get new data and
              create new ways to use data. Our Services are dynamic, and we
              often introduce new features, which may require the collection of
              new information. If we collect materially different personal data
              or materially change how we use your data, we will notify you and
              may also modify this Privacy Policy.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2. How We Use Your Data
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use your data to provide, support, personalize and develop our
              Services.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              How we use your personal data will depend on which Services you
              use, how you use those Services and the choices you make in your
              settings. We use the data that we have about you to provide and
              personalize, including with the help of automated systems and
              inferences we make, our Services (including ads) so that they can
              be more relevant and useful to you and others.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.1 Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services help you connect with others, find and be found for
              work and business opportunities, stay informed, get training and
              be more productive.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We use your data to authorize access to our Services.
            </Typography>
            <br />
          </Box>
          <br />
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              Stay Connected
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services allow you to stay in touch and up to date with
              colleagues, partners, clients, and other professional contacts. To
              do so, you will "connect" with the professionals who you choose,
              and who also wish to "connect" with you. Subject to your settings,
              when you connect with other Members, you will be able to search
              each others’ connections in order to exchange professional
              opportunities.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We will use data about you (such as your profile, profiles you
              have viewed or data provided through address book uploads or
              partner integrations) to help others find your profile, suggest
              connections for you and others (e.g. Members who share your
              contacts or job experiences) and enable you to invite others to
              become a Member and connect with you. You can also opt-in to allow
              us to use your precise location or proximity to others for certain
              tasks (e.g. to suggest other nearby Members for you to connect
              with, calculate the commute to a new job, or notify your
              connections that you are at a professional event).
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              It is your choice whether to invite someone to our Services, send
              a connection request, or allow another Member to become your
              connection. When you invite someone to connect with you, your
              invitation will include your name, photo, network and contact
              information. We will send invitation reminders to the person you
              invited. You can choose whether or not to share your own list of
              connections with your connections. Visitors have choices about how
              we use their data.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Stay Informed
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services allow you to stay informed about news, events and
              ideas regarding professional topics you care about, and from
              professionals you respect. Our Services also allow you to improve
              your professional skills, or learn new ones. We use the data we
              have about you (e.g., data you provide, data we collect from your
              engagement with our Services and inferences we make from the data
              we have about you), to recommend relevant content and
              conversations on our Services, suggest skills you may have to add
              to your profile and skills that you might need to pursue your next
              opportunity. So, if you let us know that you are interested in a
              new skill (e.g., by watching a learning video), we will use this
              information to personalize content in your feed, suggest that you
              follow certain members on our site, or watch related learning
              content to help you towards that new skill. We use your content,
              activity and other data, including your name and picture, to
              provide notices to your network and others. For example, subject
              to your settings, we may notify others that you have updated your
              profile, posted a blog, took a social action e.g. like, comment,
              follow, share, made new connections or were mentioned in the news.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>Career</Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services allow you to explore careers, evaluate educational
              opportunities, and seek out, and be found for, career
              opportunities. Your profile can be found by those looking to hire
              (for a job or a specific task) or be hired by you. We will use
              your data to recommend jobs or mentees, show you and others who
              work at a company, in an industry, function or location or have
              certain skills and connections. You can signal that you are
              interested in changing jobs and share information with job
              recruiters. We will use your data to recommend jobs to you and you
              to recruiters. We may use automated systems to profile and provide
              recommendations to help make our Services more relevant to our
              Members, Visitors and customers. Keeping your profile accurate and
              up-to-date may help you better connect to others and to
              opportunities through our Services.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Productivity
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services allow you to collaborate with colleagues, search for
              potential clients, customers, partners and others to do business
              with. Our Services allow you to communicate with other Members and
              schedule and prepare meetings with them. If your settings allow,
              we scan messages to provide "bots" or similar tools that
              facilitate tasks such as scheduling meetings, drafting responses,
              summarizing messages or recommending next steps. Learn more.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.2 Premium Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our premium Services allow paying users to search for and contact
              Members through our Services, such as searching for and contacting
              job candidates, sales leads and co-workers, manage talent and
              promote content through social media.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We sell premium Services that provide our customers and
              subscribers customized-search functionality and tools (including
              messaging and activity alerts) as part of our talent, marketing
              and sales solutions. Customers can export limited information from
              your profile, such as name, headline, current company, current
              title, and general location (e.g., Dublin), in order to manage
              sales leads or talent, unless you opt-out. We do not provide
              contact information to customers as part of these premium Services
              without your consent. A premium Services customer can store
              information he/she has about you in our premium Services, such as
              a resume or contact information or sales history. The data
              provided about you by these customers is subject to the policies
              of those customers.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We contact you and enable communications between Members. We offer
              settings to control what messages you receive and how often you
              receive some types of messages.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We will contact you through email, mobile phone, notices posted on
              our websites or apps, messages to your Desi Hisab inbox, and other
              ways through our Services, including text messages and push
              notifications. We will send you messages about the availability of
              our Services, security, or other service-related issues. We also
              send messages about how to use the Services, network updates,
              reminders, job suggestions and promotional messages from us and
              our partners. You may change your communication preferences at any
              time. Please be aware that you cannot opt-out of receiving service
              messages from us, including security and legal notices. We also
              enable communications between you and others through our Services,
              including for example invitation, groups and messages between
              connections.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.4 Advertising
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We serve you tailored ads both on and off our Services. We offer
              you choices regarding personalized ads, but you cannot opt-out of
              seeing other ads.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We target (and measure the performance of) ads to Members,
              Visitors and others both on and off our Services directly or
              through a variety of partners:
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              Partners include ad networks, exchanges and others, using the
              following data, whether separately or combined:
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Data from advertising technologies on and off our Services, like
              web beacons, pixels, ad tags, cookies, and device identifiers;
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Member-provided information (e.g., profile, contact information,
              title and industry);
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Data from your use of our Services (e.g., search history, feed,
              content you read, who you follow or is following you, connections,
              groups participation, page visits, videos you watch, clicking on
              an ad, etc.), including as described in Section 1.3;
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Information from advertising partners and publishers [Learn More];
              and
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Information inferred from data described above (e.g., using job
              titles from a profile to infer industry, seniority, and
              compensation bracket; using graduation dates to infer age or using
              first names or pronoun usage to infer gender).
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We will show you ads called sponsored content which look similar
              to non-sponsored content, except that they are labeled "ads" or
              "sponsored." If you take an action (such as like, comment or
              share) on these ads, your action is associated with your name and
              viewable by others, including the advertiser. Subject to your
              settings, if you take a social action on the Desi Hisab Services,
              that action. For example, when you like a company, we may include
              your name and photo when their sponsored content is shown to your
              connections may be mentioned with related ads.
            </Typography>
          </Box>
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              Ad Choices
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We adhere to self-regulatory principles for interest-based
              advertising and participate in industry opt-outs from such ads.
              This does not opt you out of receiving advertising; you will
              continue to get other ads by advertisers not listed with these
              self regulatory tools. You can also opt-out specifically from our
              uses of certain categories of data to show you more relevant ads.
              For Visitors, the setting is here.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Info to Ad Providers
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We do not share your personal data with any third-party
              advertisers or ad networks for their advertising except for: (i)
              hashed or device identifiers (to the extent they are personal data
              in some countries); (ii) with your separate permission (e.g., lead
              generation form) or (iii) data already visible to any users of the
              Services (e.g. profile). However, if you view or click on an ad on
              or off our site or apps, the ad provider will get a signal that
              someone visited the page that displayed the ad, and they may
              through the use of mechanisms such as cookies determine it is you.
              Advertising partners can associate personal data collected by the
              advertiser directly from you with our cookies and similar
              technologies. In such instances, we seek to contractually require
              such advertising partners to obtain your explicit, opt-in consent
              before doing so.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.5 Marketing
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We promote our Services to you and others.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use data and content about Members for invitations and
              communications promoting membership and network growth, engagement
              and our Services.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.6 Developing Services and Research
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We develop our Services and conduct research.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Service Development
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use data, including public feedback, to conduct research and
              development for the further development of our Services in order
              to provide you and others with a better, more intuitive and
              personalized experience, drive membership growth and engagement on
              our Services, and help connect professionals to each other and to
              economic opportunity.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Other Research
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We seek to create economic opportunity for Members of the global
              workforce and to help them be more productive and successful. We
              use the personal data available to us to research social, economic
              and workplace trends such as jobs availability and skills needed
              for these jobs and policies that help bridge the gap in various
              industries and geographic areas. In some cases, we work with
              trusted third parties to perform this research, under controls
              that are designed to protect your privacy. We publish or allow
              others to publish economic insights, presented as aggregated data
              rather than personal data.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Surveys
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Polls and surveys are conducted by us and others through our
              Services. You are not obligated to respond to polls or surveys,
              and you have choices about the information you provide. You may
              opt-out of survey invitations.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.7 Customer Support
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use data to help you and fix problems.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We use the data (which can include your communications) to
              investigate, respond to and resolve complaints and Service issues
              (e.g., bugs).
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.8 Aggregate Insights
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use data to generate aggregate insights.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We use your data to produce and share aggregated insights that do
              not identify you. [Learn More]. For example we may use your data
              including inferences drawn from data about you such as your age or
              gender to generate statistics about our members, their profession
              or industry, to calculate ad impressions served or clicked on, or
              to publish visitor demographics for a Service or demographic
              workforce insights.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              2.9 Security and Investigations
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use data for security, fraud prevention and investigations.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We use your data (including your communications) if we think it’s
              necessary for security purposes or to investigate possible fraud
              or other violations of our User Agreement or this Privacy Policy
              and/or attempts to harm our Members or Visitors.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3. How We Share Information
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.1 Our Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Any data that you include on your profile and any content you post
              or social action (e.g. likes, follows, comments, shares) you take
              on our Services will be seen by others.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              Profile
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Your profile is fully visible to all Members and customers of our
              Services. Subject to your settings, it can also be visible to
              others on or off of our Services (e.g., Visitors to our Services
              or users of third- party search engines). As detailed in our Help
              Center, your settings, degree of connection with the viewing
              Member, the subscriptions they may have, their usage of our
              Services, access channels and search types (e.g., by name or by
              keyword) impact the availability of your profile and whether they
              can view certain fields in your profile.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Posts, Likes, Follows, Comments, Messages
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our Services allow viewing and sharing information including
              through posts, likes, follows and comments.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              When you share an article or a post (e.g., an update, image, video
              or article) publicly it can be viewed by everyone and re-shared
              anywhere (subject to your settings). Members, Visitors and others
              will be able to find and see your publicly-shared content,
              including your name (and photo if you have provided one).
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              In a group, posts are visible to others in the group. Your
              membership in groups is public and part of your profile, but you
              can change visibility in your settings.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Any information you share through companies’ or other
              organizations’ pages on our Services will be viewable by it and
              others who visit those pages.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              When you follow a person or organization, you are visible to
              others and that "page owner" as a follower.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              We let senders know when you act on their message, subject to your
              settings where applicable.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              Subject to your settings, we let a Member know when you view their
              profile.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>
              When you like or re-share or comment on another’s content
              (including ads), others will be able to view these "social
              actions" and associate it with you (e.g., your name, profile and
              photo if you provided it).
            </Typography>
            <br />
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Your employer can see how you use Services they provided for your
              work (e.g. as a recruiter or sales agent) and related information.
              We will not show them your job searches or personal messages.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              Enterprise Accounts
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Your employer may offer you access to our enterprise Services such
              as Recruiter, Sales Navigator, Desi Hisab Learning or our
              advertising Campaign Manager. Your employer can review and manage
              your use of such enterprise Services.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Depending on the enterprise Service, before you use such Service,
              we will ask for permission to share relevant data from your
              profile or use of our non-enterprise Services. We understand that
              certain activities such as job hunting and personal messages are
              sensitive, and so we do not share those with your employer unless
              you choose to share it with them through our Services (for
              example, by applying for a new position in the same company or
              mentioning your job hunting in a message to a co-worker through
              our Services).
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.2 Communication Archival
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Regulated Members may need to store communications outside of our
              Service.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              Some Members (or their employers) need, for legal or professional
              compliance, to archive their communications and social media
              activity, and will use services of others to provide these
              archival services. We enable archiving of messages by those
              Members outside of our Services. For example, a financial advisor
              needs to archive communications with her clients through our
              Services in order to maintain her professional financial advisor
              license.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.3 Others’ Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              You may link your account with others’ services so that they can
              look up your contacts’ profiles, post your shares on such
              platforms, or start conversations with your connections on such
              platforms. Excerpts from your profile will also appear on the
              services of others.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Subject to your settings, other services may look-up your profile.
              When you opt to link your account with other services, personal
              data will become available to them. The sharing and use of that
              personal data will be described in, or linked to, a consent screen
              when you opt to link the accounts. For example, you may link your
              Twitter or WeChat account to share content from our Services into
              these other services, or your email provider may give you the
              option to upload your Desi Hisab contacts into its own service.
              You may revoke the link with such accounts.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Subject to your settings, excerpts from your profile will appear
              on the services of others (e.g., search engine results, mail and
              calendar applications that show a user a "mini" Desi Hisab profile
              of the person they are meeting or messaging, social media
              aggregators; For example, company page administrators may manage
              their brand's online presence by aggregating users' comments and
              other social actions across social networks and see their basic
              profiles in that context., talent and lead managers). "Old"
              profile information remains on these services until they update
              their data cache with changes you made to your profile.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.4 Related Services
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We share your data across our different Services and Desi
              Hisab-affiliated entities.
            </Typography>
            <br />
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We will share your personal data with our affiliates to provide
              and develop our Services. We may combine information internally
              across the different Services covered by this Privacy Policy to
              help our Services be more relevant and useful to you and others.
              For example, some apps will recommend better content to you based
              on your Desi Hisab profile or the articles you read on the Desi
              Hisab Services, and we can personalize your feed or job
              recommendations based on your learning video history, because we
              are able to identify you across different Services using cookies
              or similar technologies.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.5 Service Providers
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We may use others to help us with our Services.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We use others to help us provide our Services (e.g., maintenance,
              analysis, audit, payments, fraud detection, marketing and
              development). They will have access to your information as
              reasonably necessary to perform these tasks on our behalf and are
              obligated not to disclose or use it for other purposes.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.6 Legal Disclosures
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We may need to share your data when we believe it’s required by
              law or to help protect the rights and safety of you, us or others.
              It is possible that we will need to disclose information about you
              when required by law, subpoena, or other legal process or if we
              have a good faith belief that disclosure is reasonably necessary
              to (1) investigate, prevent, or take action regarding suspected or
              actual illegal activities or to assist government enforcement
              agencies; (2) enforce our agreements with you, (3) investigate and
              defend ourselves against any third-party claims or allegations,
              (4) protect the security or integrity of our Service (such as by
              sharing with companies facing similar threats); or (5) exercise or
              protect the rights and safety of Desi Hisab, our Members,
              personnel, or others. We attempt to notify Members about legal
              demands for their personal data when appropriate in our judgment,
              unless prohibited by law or court order or when the request is an
              emergency. We may dispute such demands when we believe, in our
              discretion, that the requests are overbroad, vague or lack proper
              authority, but we do not promise to challenge every demand.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              3.7 Change in Control or Sale
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We may share your data when our business is sold to others, but it
              must continue to be used in accordance with this Privacy Policy.
              We can also share your personal data as part of a sale, merger or
              change in control, or in preparation for any of these events. Any
              other entity which buys us or part of our business will have the
              right to continue to use your data, but only in the manner set out
              in this Privacy Policy unless you agree otherwise.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              4. Your Choices & Obligations
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              4.1 Data Retention
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We keep most of your personal data for as long as your account is
              open.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We retain your personal data while your account is in existence or
              as needed to provide you Services. This includes data you or
              others provided to us and data generated or inferred from your use
              of our Services. Even if you only use our Services when looking
              for a new job every few years, we will retain your information and
              keep your profile open until you decide to close your account. In
              some cases we choose to retain certain information (e.g., visits
              to sites carrying our "share with Desi Hisab" or "apply with Desi
              Hisab" plugins without clicking on the plugin) in a depersonalized
              or aggregated form.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              4.2 Rights to Access and Control Your Personal Data
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              You can access or delete your personal data. You have many choices
              about how your data is collected, used and shared.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We provide many choices about the collection, use and sharing of
              your data, from deleting or correcting data you include in your
              profile and controlling the visibility of your posts to
              advertising opt-outs and communication controls. We offer you
              settings to control and manage the personal data we have about
              you.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              For personal data that we have about you:
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>Delete Data: You can ask us to erase or delete all or
              some of your personal data (e.g., if it is no longer necessary to
              provide Services to you).
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>Change or Correct Data: You can edit some of your
              personal data through your account. You can also ask us to change,
              update or fix your data in certain cases, particularly if it’s
              inaccurate.
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>Object to, or Limit or Restrict, Use of Data: You can ask
              us to stop using all or some of your personal data (e.g., if we
              have no legal right to keep using it) or to limit our use of it
              (e.g., if your personal data is inaccurate or unlawfully held).
            </Typography>
            <Typography className={styles.privacyPolicyInformationli}>
              <li></li>Right to Access and/or Take Your Data: You can askHow to
              do this: Members may go to their settings to make requests. us for
              a copy of your personal data and can ask for a copy of personal
              data you provided in machine readable form.
            </Typography>
            <br />
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Visitors can learn more about how to make these requests here. You
              may also contact us using the contact information below, and we
              will consider your request in accordance with applicable laws.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              Residents in the Designated Countries may have additional rights
              under their laws. Learn More.
            </Typography>
          </Box>
          <br />
          <Box>
            <Typography className={styles.privacyPolicyHead}>
              4.3 Account Closure
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We keep some of your data even after you close your account.
            </Typography>
            <br />
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              If you choose to close your Desi Hisab account, your personal data
              will generally stop being visible to others on our Services within
              24 hours. We generally delete closed account information within 30
              days of account closure, except as noted below.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We retain your personal data even after you have closed your
              account if reasonably necessary to comply with our legal
              obligations (including law enforcement requests), meet regulatory
              requirements, resolve disputes, maintain security, prevent fraud
              and abuse, enforce our User Agreement, or fulfill your request to
              "unsubscribe" from further messages from us. We will retain
              de-personalized information after your account has been closed.
            </Typography>
            <br />
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Information you have shared with others (e.g., through InMail,
              updates or group posts) will remain visible after you closed your
              account or deleted the information from your own profile or
              mailbox, and we do not control data that other Members copied out
              of our Services. Groups content and ratings or review content
              associated with closed accounts will show an unknown user as the
              source. Your profile may continue to be displayed in the services
              of others (e.g., search engine results) until they refresh their
              cache.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5. Other Important Information
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5.1. Security
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We monitor for and try to prevent security breaches. Please use
              the security features available through our Services.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We implement security safeguards designed to protect your data,
              such as HTTPS. We regularly monitor our systems for possible
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              vulnerabilities and attacks. However, we cannot warrant the
              security of any information that you send us. There is no
              guarantee that data
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              may not be accessed, disclosed, altered, or destroyed by breach of
              any of our physical, technical, or managerial safeguards.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5.2. Cross-Border Data Transfers
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We store and use your data outside your country.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We process data both inside and outside of the United States and
              rely on legally-provided mechanisms to lawfully transfer data
              across
            </Typography>

            <Typography className={styles.privacyPolicyInformation}>
              borders. Countries where we process data may have laws which are
              different, and potentially not as protective, as the laws of your
              own country.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5.3 Lawful Bases for Processing
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We have lawful bases to collect, use and share data about you. You
              have choices about our use of your data. At any time, you can
              withdraw consent you have provided by going to settings.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              We will only collect and process personal data about you where we
              have lawful bases. Lawful bases include consent where we process
              data based on consent, we will ask for your explicit consent. You
              may withdraw your consent at any time, but that will not affect
              the lawfulness of the processing of your personal data prior to
              such withdrawal. Where we rely on contract, we will ask that you
              agree to the processing of personal data that is necessary for
              entering into or performance of your contract with us. We will
              rely on legitimate interests as a basis for data processing where
              the processing of your data is not overridden by your interests or
              fundamental rights and freedoms.(where you have given consent),
              contract (where processing is necessary for the performance of a
              contract with you (e.g. to deliver the Desi Hisab Services you
              have requested)) and "legitimate interests".
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Where we rely on your consent to process personal data, you have
              the right to withdraw or decline your consent at any time and
              where we rely on legitimate interests, you have the right to
              object. If you have any questions about the lawful bases upon
              which we collect and use your personal data, please contact our
              Data Protection Officer.
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5.4. Direct Marketing and Do Not Track Signals
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              Our statements regarding direct marketing and "do not track"
              signals.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              We currently do not share personal data with third parties for
              their direct marketing purposes without your permission.{" "}
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyHead}>
              5.5. Contact Information
            </Typography>
            <br />
            <Typography className={styles.privacyPolicyInformation}>
              You can contact us or use other options to resolve any complaints.
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              If you have questions or complaints regarding this Policy, please
              first contact Desi Hisab online. You can also reach us by physical
              mail. If
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              contacting us does not resolve your complaint, you have more
              options. Residents in the Designated Countries, we use the term
            </Typography>
            <Typography className={styles.privacyPolicyInformation}>
              "Designated Countries" to refer to countries in the European Union
              (EU), European Economic Area (EEA), and Switzerland may also have
              the right to contact our Data Protection Officer.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PrivacyHeader;
