import { Grid } from "@mui/material";
import CashRewards1 from "./CashRewards1";
import InviteFriendLogo from "./InviteFriendLogo";
import styles from "./InviteFriendsSection.module.css";
import Container from "@mui/material/Container";
function InviteFriendsSection() {
  return (
    <div className={styles.InviteFriendsSection_mainsection}>
      <Container maxWidth="xl">
        <Grid container className={styles.InviteFriendsSection_mainwrap}>
          <Grid item xs={12} md={6} className={styles.InviteFriendsSection}>
            <CashRewards1 />
          </Grid>
          <Grid item xs={12} md={6} className={styles.logosection}>
            <InviteFriendLogo />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default InviteFriendsSection;
