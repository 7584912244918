import { Grid } from "@mui/material";
import lineImage from "../../../images/Line 3.png";
import { howtouseapptext } from "../../Constants";
import styles from "./AboutUs.module.css";
import howtouseappimg from "../../../images/choose-way-to-enter-app.png";
import Mainstyle from "../../../style.module.css";

function Howtouseapp() {
  
  return (
    <div>
      
      <Grid container alignItems="center">
        <Grid item xs={12} md={6} className={Mainstyle.containerwrap}>
          <img src={lineImage} className={styles.lineImage} alt="Desi Hisab" />
          <span className={Mainstyle.incomeTitleexpenses}>How to Use</span>
          <div>
          <span className={Mainstyle.title}>
          Step by Step App Use
            
          </span></div>
          {howtouseapptext.map((data) => (
            <div key={data.id} className={`${Mainstyle.flexwrapper} ${Mainstyle['mt-51']}`}>
              <div className={Mainstyle.circlewithlogo}>
                <div className={Mainstyle.circlewithtext}>
                  <img src={data.icon} alt="Desi Hisab" />
                </div>
              </div>
              <div className={Mainstyle.icontextwrap}>
                <span className={Mainstyle.textheading}>{data.heading}</span>
                <span className={Mainstyle.textdescription}>
                  {data.description}
                </span>
              </div>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} md={6} className={styles.imgright}>
          <img src={howtouseappimg} alt="How to use app" />
        </Grid>
      </Grid>
    </div>
  );
}

export default Howtouseapp;