import React from "react";
import styles from "../About/AboutUs.module.css";
import Mainstyle from "../About/AboutUs.module.css";
import { Grid, Typography } from "@mui/material";
import {welcometext} from "../../Constants";

const Welcome = () => {
  return (
    
    <section className={styles.welcomemainwrap}>
      <Grid container className={styles.featuresmainwrap}>
        <Grid item xs={12}>
          <div className={styles.welcometitle}>{welcometext.title}</div>
          <div className={styles.welcometext}>{welcometext.subTitle}</div>
        </Grid>
      </Grid>
      
    </section>
  );
};

export default Welcome;
