import React from 'react'
import { Box, Grid } from "@mui/material";
import styles from '../FooterSecurity/Security.module.css'

const FooterSecurityHeader = () => {
  return (
    <Grid item xs={12} className={styles.securityHeader}>
        <Box className={styles.securityTitle}>
            <h3 className={styles.securityHead}>Security</h3>
        </Box>
        <Box className={styles.securityText}>
            <h1 className={styles.securityHead}>Coming Soon</h1>
        </Box>
    </Grid>
  )
}
export default FooterSecurityHeader;