import React from "react";
import FooterSection from "../Footer/FooterSection";
import Navbar from "../PrivacyPolicy/Navbar";
import Errorsection from "./Errorsection";
import { Grid } from "@mui/material";

const Error404Page = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Navbar />
        <Errorsection />
        <FooterSection />
      </Grid>
    </Grid>
  );
};

export default Error404Page;
